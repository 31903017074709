import React, { Component } from 'react';
import classNames from 'classnames';
import { TweenLite, TweenMax, Power2 } from 'gsap/all';
// eslint-disable-next-line
import morphSVG from '../plugins/MorphSVGPlugin';
import PropTypes from 'prop-types';
import $ from 'jquery';

import API from '../services/api';
import Logo from '../components/logo';
import History from '../helpers/history';
import ContactInfo from './contactInfo';

const propTypes = {
  navigate: PropTypes.func.isRequired
};

class Navigation extends Component {
  state = {
    open: false,
    transitioning: false
  };

  componentDidMount() {
    $(window).on('resize', this.setVH);
    this.setVH();
    setTimeout(() => {
      this.setState({ loaded: true });
    }, 500);

    API.retrieveContent({ prefix: 'page', path: 'contact' }).then(res => {
      this.setState({ contact: res.data });
    });
  }

  componentWillUnmount() {
    $(window).off('resize', this.setVH);
  }

  setVH = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  navigate = (event, page) => {
    event.preventDefault();

    if (window.location.pathname !== page) {
      this.props.navigate(event, page);
      this.closeNav();
    }

    this.closeNav();
  };

  openNav = () => {
    TweenLite.set('.contact-info', {
      opacity: 1,
      y: 0
    });

    TweenLite.to('#backStart', 0.3, {
      morphSVG: '#backEnd',
      ease: Power2.easeOut,
      onComplete: () => {
        TweenMax.staggerFromTo(
          '.greater-navigation__content-list__item',
          0.5,
          {
            opacity: 0,
            bottom: -30
          },
          {
            opacity: 1,
            bottom: 0,
            delay: 0.1,
            ease: Power2.easeOut,
            force3D: true
          },
          0.1
        );
      }
    });

    TweenLite.to('#frontStart', 0.3, {
      morphSVG: '#frontEnd',
      ease: Power2.easeOut,
      delay: 0.2,
      onComplete: () => {
        TweenLite.to('.contact-info__container', 0.5, {
          opacity: 1,
          y: 0
        });
      }
    });
  };

  closeNav = () => {
    setTimeout(() => {
      this.open = false;
      this.forceUpdate();
    }, 1000);

    TweenLite.to('.contact-info', 0.5, {
      opacity: 0,
      y: 30,
      autoAlpha: true
    });

    TweenMax.staggerTo(
      '.greater-navigation__content-list__item',
      0.2,
      {
        opacity: 0,
        bottom: 20,
        delay: 0.1,
        ease: Power2.easeIn,
        force3D: true
      },
      0.1
    );
    TweenLite.to('#backStart', 0.3, {
      morphSVG: '#backStart',
      ease: Power2.easeIn,
      delay: 0.75,
      onComplete: () => {
        this.forceUpdate();
      }
    });

    TweenLite.to('#frontStart', 0.3, {
      morphSVG: '#frontStart',
      delay: 0.5,
      ease: Power2.easeIn
    });
  };

  toggleNav = () => {
    this.open = !this.open;

    if (this.open) {
      this.forceUpdate();
      setTimeout(() => {
        this.openNav();
      }, 1);
    } else {
      this.closeNav();
    }
  };

  render() {
    const pageName = History.location.pathname.split('/')[1];
    const navContentClasses = classNames('greater-navigation', {
      open: this.open,
      loaded: this.state.loaded,
      [History.location.pathname.split('/')[1]]: true
    });

    const needLogo = pageName !== '' || this.open;

    return (
      <nav className="greater-navigation__container">
        <div className={navContentClasses}>
          <nav className="greater-navigation__header">
            <div className="logo" onClick={e => this.navigate(e, '/')}>
              {needLogo && <Logo className="home" />}
            </div>

            <div className="hamburger" onClick={this.toggleNav}>
              <div className="hamburger-helper">Get Started</div>
              <div className="hamburger-lines">
                <div className="hamburger-line burger-top" />
                <div className="hamburger-line burger-middle" />
                <div className="hamburger-line burger-bottom" />
              </div>
            </div>
          </nav>
          {this.open && (
            <div className="greater-navigation__content">
              <ul className="greater-navigation__content-list">
                <li className="greater-navigation__content-list__item">
                  <a href="/about" onClick={e => this.navigate(e, '/about')}>
                    About
                  </a>
                </li>
                <li className="greater-navigation__content-list__item">
                  <a href="/work" onClick={e => this.navigate(e, '/work')}>
                    Work
                  </a>
                </li>
                <li className="greater-navigation__content-list__item">
                  <a
                    href="/process"
                    onClick={e => this.navigate(e, '/process')}
                  >
                    Process
                  </a>
                </li>

                <li className="greater-navigation__content-list__item">
                  <a
                    href="/alignment"
                    onClick={e => this.navigate(e, '/alignment')}
                  >
                    Alignment
                  </a>
                </li>

                <li className="greater-navigation__content-list__item">
                  <a
                    href="/writing"
                    onClick={e => this.navigate(e, '/writing')}
                  >
                    Writing
                  </a>
                </li>
                <li className="greater-navigation__content-list__item">
                  <a
                    href="/contact"
                    onClick={e => this.navigate(e, '/contact')}
                  >
                    Contact
                  </a>
                </li>
              </ul>
              <svg
                className="navigation-background"
                x="0px"
                y="0px"
                width="100%"
                height="100%"
                viewBox="0 0 1024 768"
                preserveAspectRatio="none"
              >
                <path
                  id="backStart"
                  display="inline"
                  opacity="0.9"
                  fill="#7aaedb"
                  d="M512-388.995H0v28.792c0,0,150.428,360.208,512,360.208 s512-360.208,512-360.208v-28.792H512z"
                />
                <path
                  id="backEnd"
                  display="inline"
                  opacity="0.9"
                  fill="#7aaedb"
                  d="M512,0H0v768c0,0,150.428,0,512,0s512,0,512,0V0H512z"
                />
                <path
                  id="frontStart"
                  opacity="0.9"
                  fill="#248dc1"
                  d="M512-388.995H0v28.792c0,0,150.428,360.208,512,360.208s512-360.208,512-360.208v-28.792H512z"
                />
                <path
                  id="frontEnd"
                  opacity="0.9"
                  fill="#248dc1"
                  d="M512,0H0v768c0,0,150.428,0,512,0s512,0,512,0V0H512z"
                />
              </svg>
              {this.state.contact && <ContactInfo info={this.state.contact} />}
            </div>
          )}
        </div>
      </nav>
    );
  }
}

Navigation.propTypes = propTypes;
export default Navigation;
