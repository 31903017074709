import React, { Component } from 'react';
import { TweenMax, Power2 } from 'gsap/all';
// eslint-disable-next-line
import drawSvg from '../plugins/DrawSVGPlugin';
import PropTypes from 'prop-types';

const propTypes = {
  className: PropTypes.string
}

class Logo extends Component {
  componentDidMount() {
    // Expand dashes
    TweenMax.fromTo(
      '#bottom_dash',
      0.5,
      {
        drawSVG: '0%'
      },
      {
        drawSVG: '100%'
      }
    );

    TweenMax.fromTo(
      '#top_dash',
      0.5,
      {
        drawSVG: '0%'
      },
      {
        drawSVG: '100%'
      }
    );

    // Rotate Dashes
    TweenMax.to('#top_dash', 0.5, {
      delay: 0.5,
      transformOrigin: '100% 50%',
      rotation: '30%'
    });

    TweenMax.to('#bottom_dash', 0.5, {
      delay: 0.5,
      transformOrigin: '100% 50%',
      rotation: '-30%'
    });

    // Fill Circle
    TweenMax.fromTo(
      '#circle',
      1.5,
      {
        transformOrigin: '50% 50%',
        rotation: '0%',
        drawSVG: '0%'
      },
      {
        rotation: '135%',
        drawSVG: '100%',
        easing: Power2.easeOut
      }
    ).delay(0.5);

    // Logo
    TweenMax.fromTo(
      '#logo',
      1,
      {
        y: 16
      },
      {
        y: 0
      }
    ).delay(1);

    // Tagline
    TweenMax.fromTo(
      '#tagline',
      1,
      {
        y: -13
      },
      {
        y: 0
      }
    ).delay(1);

    TweenMax.fromTo(
      '#period',
      0.5,
      {
        opacity: 0
      },
      {
        opacity: 1
      }
    ).delay(2);
  }

  render() {
    return (
      <svg
        x="0px"
        y="0px"
        width="154.811px"
        height="33.002px"
        viewBox="0 0 154.811 33.002"
        className={this.props.className}
      >
        <defs>
          <mask id="logoMask">
            <rect x="36" y="4" width="115" height="16" fill="#fff" />
          </mask>
          <mask id="taglineMask">
            <rect x="36" y="20" width="115" height="16" fill="#fff" />
          </mask>
        </defs>
        <g mask="url(#logoMask)">
          <g id="logo">
            <path
              d="M48.415,19.038c-0.118,0-0.236-0.07-0.33-0.165l-0.566-0.683c-1.036,0.613-2.238,0.966-3.511,0.966
       c-3.769,0-6.832-3.063-6.832-6.833s3.063-6.832,6.832-6.832c1.885,0,3.605,0.754,4.831,1.979c0.047,0.071,0.094,0.165,0.094,0.236
       c0,0.094-0.047,0.165-0.094,0.212l-1.343,1.296c-0.047,0.047-0.095,0.07-0.165,0.07c-0.094,0-0.189-0.047-0.259-0.117
       c-0.849-0.778-1.862-1.202-3.086-1.202c-2.451,0-4.147,1.979-4.147,4.43c0,2.45,1.696,4.405,4.147,4.405
       c1.107,0,2.073-0.354,2.851-0.99v-1.649h-2.403c-0.165,0-0.283-0.118-0.283-0.283v-1.813c0-0.165,0.118-0.283,0.283-0.283h4.782
       c0.166,0,0.283,0.118,0.283,0.283v6.691c0,0.164-0.118,0.282-0.283,0.282H48.415z"
            />

            <path
              d="M57.348,11.922c-1.673,0.095-2.003,1.037-2.003,2.121v4.713c0,0.164-0.118,0.282-0.283,0.282h-2.097
       c-0.165,0-0.283-0.118-0.283-0.282V9.896c0-0.165,0.118-0.283,0.283-0.283h1.272c0.164,0,0.259,0.095,0.329,0.283l0.142,0.778
       c0.989-0.918,1.814-1.131,2.639-1.179h0.707c0.165,0,0.282,0.118,0.282,0.283v1.862c0,0.165-0.117,0.282-0.282,0.282H57.348z"
            />

            <path
              d="M68.211,15.127h-6.125c0,1.579,1.531,1.814,2.215,1.814c0.683,0,1.743-0.188,2.238-0.636
       c0.141-0.094,0.259-0.141,0.354-0.141c0.07,0,0.117,0.023,0.165,0.07l0.706,1.178c0.048,0.048,0.071,0.142,0.071,0.236
       c0,0.071-0.023,0.189-0.071,0.259c-0.824,1.037-2.19,1.249-3.439,1.249c-2.686,0-4.923-1.437-4.923-4.76
       c0-2.615,1.366-4.9,4.546-4.9c2.592,0,4.57,1.673,4.57,4.688c0,0.33-0.023,0.26-0.023,0.66
       C68.494,15.009,68.375,15.127,68.211,15.127 M62.085,13.454h3.839c0-1.131-1.225-1.484-1.932-1.484
       C63.263,11.97,62.085,12.347,62.085,13.454"
            />

            <path
              d="M76.223,18.024c-0.754,1.131-2.309,1.131-2.568,1.131c-1.037,0-3.37-0.306-3.37-3.039c0-2.05,1.367-2.993,3.323-2.993
       c0.825,0,1.649,0.165,2.144,0.518c0-1.225-0.495-1.72-1.86-1.72c-0.283,0-1.391,0-2.074,0.26
       c-0.024,0.022-0.071,0.022-0.094,0.022c-0.047,0-0.165-0.022-0.236-0.188l-0.565-1.391c-0.047-0.117-0.071-0.164-0.071-0.259
       c0-0.07,0-0.141,0.047-0.212c1.295-0.542,2.239-0.66,3.133-0.66c3.063,0,4.406,1.625,4.406,4.265v4.996
       c0,0.164-0.118,0.282-0.283,0.282h-1.437c-0.165,0-0.306-0.094-0.377-0.282L76.223,18.024z M75.776,16.423v-0.707
       c-0.213-0.33-0.448-0.613-1.391-0.613c-1.178,0-1.696,0.33-1.696,0.825c0,0.73,0.612,1.037,1.626,1.037
       C74.48,16.965,75.328,16.894,75.776,16.423"
            />

            <path
              d="M85.107,11.922v2.709c0,1.791,0.094,2.168,0.895,2.168c0.236,0,0.424,0,0.73-0.047c0.048-0.024,0.095-0.024,0.165-0.024
       c0.095,0,0.236,0.094,0.26,0.189l0.377,1.602c0.023,0.047,0.023,0.118,0.023,0.165c0,0.118-0.118,0.235-0.141,0.235
       c-0.684,0.236-0.943,0.236-1.767,0.236c-2.027,0-3.205-0.872-3.205-4.006v-3.228h-1.602c-0.165,0-0.283-0.117-0.283-0.282V9.779
       c0-0.165,0.118-0.283,0.283-0.283h1.602V5.939c0-0.165,0.118-0.283,0.283-0.283h2.097c0.165,0,0.283,0.118,0.283,0.283v3.557
       h2.026c0.165,0,0.283,0.118,0.283,0.283v1.862c0,0.165-0.118,0.282-0.283,0.282H85.107z"
            />

            <path
              d="M98.043,15.127h-6.125c0,1.579,1.531,1.814,2.215,1.814c0.683,0,1.743-0.188,2.237-0.636
       c0.142-0.094,0.259-0.141,0.354-0.141c0.07,0,0.117,0.023,0.164,0.07l0.707,1.178c0.047,0.048,0.071,0.142,0.071,0.236
       c0,0.071-0.024,0.189-0.071,0.259c-0.824,1.037-2.191,1.249-3.439,1.249c-2.686,0-4.923-1.437-4.923-4.76
       c0-2.615,1.366-4.9,4.546-4.9c2.592,0,4.571,1.673,4.571,4.688c0,0.33-0.023,0.26-0.023,0.66
       C98.326,15.009,98.208,15.127,98.043,15.127 M91.918,13.454h3.84c0-1.131-1.226-1.484-1.933-1.484
       C93.096,11.97,91.918,12.347,91.918,13.454"
            />

            <path
              d="M105.491,11.922c-1.673,0.095-2.003,1.037-2.003,2.121v4.713c0,0.164-0.118,0.282-0.283,0.282h-2.097
       c-0.165,0-0.283-0.118-0.283-0.282V9.896c0-0.165,0.118-0.283,0.283-0.283h1.272c0.164,0,0.259,0.095,0.329,0.283l0.142,0.778
       c0.989-0.918,1.813-1.131,2.639-1.179h0.707c0.165,0,0.282,0.118,0.282,0.283v1.862c0,0.165-0.117,0.282-0.282,0.282H105.491z"
            />

            <path
              d="M110.936,8.083h-3.228c-0.165,0-0.283-0.118-0.283-0.283V5.939c0-0.165,0.118-0.283,0.283-0.283h9.117
       c0.165,0,0.283,0.118,0.283,0.283V7.8c0,0.165-0.118,0.283-0.283,0.283h-3.228v10.673c0,0.164-0.118,0.282-0.283,0.282h-2.097
       c-0.165,0-0.282-0.118-0.282-0.282V8.083z"
            />

            <path
              d="M122.128,10.203c0.942-0.707,1.555-0.707,2.309-0.707c2.663,0,3.841,1.98,3.841,4.217v5.043
       c0,0.164-0.118,0.282-0.283,0.282h-2.097c-0.165,0-0.283-0.118-0.283-0.282v-4.713c0-1.155-0.141-2.121-1.814-2.121
       c-1.178,0-1.673,0.966-1.673,2.121v4.713c0,0.164-0.118,0.282-0.283,0.282h-2.097c-0.165,0-0.283-0.118-0.283-0.282V5.939
       c0-0.165,0.118-0.283,0.283-0.283h2.05c0.165,0,0.259,0.094,0.33,0.283V10.203z"
            />

            <path
              d="M136.573,18.024c-0.754,1.131-2.309,1.131-2.568,1.131c-1.037,0-3.37-0.306-3.37-3.039c0-2.05,1.367-2.993,3.323-2.993
       c0.825,0,1.649,0.165,2.144,0.518c0-1.225-0.495-1.72-1.861-1.72c-0.283,0-1.391,0-2.074,0.26
       c-0.024,0.022-0.071,0.022-0.094,0.022c-0.047,0-0.165-0.022-0.236-0.188l-0.565-1.391c-0.047-0.117-0.071-0.164-0.071-0.259
       c0-0.07,0-0.141,0.047-0.212c1.296-0.542,2.239-0.66,3.133-0.66c3.063,0,4.406,1.625,4.406,4.265v4.996
       c0,0.164-0.118,0.282-0.283,0.282h-1.437c-0.165,0-0.306-0.094-0.377-0.282L136.573,18.024z M136.126,16.423v-0.707
       c-0.213-0.33-0.448-0.613-1.391-0.613c-1.178,0-1.696,0.33-1.696,0.825c0,0.73,0.612,1.037,1.626,1.037
       C134.831,16.965,135.678,16.894,136.126,16.423"
            />

            <path
              d="M143.997,10.674c1.106-1.037,2.025-1.179,2.944-1.179c2.663,0,3.841,1.98,3.841,4.217v5.043
       c0,0.164-0.118,0.282-0.283,0.282h-2.097c-0.165,0-0.283-0.118-0.283-0.282v-4.713c0-1.155-0.141-2.121-1.813-2.121
       c-1.178,0-1.673,0.966-1.673,2.121v4.713c0,0.164-0.118,0.282-0.283,0.282h-2.097c-0.165,0-0.283-0.118-0.283-0.282V9.896
       c0-0.165,0.118-0.283,0.283-0.283h1.296c0.165,0,0.259,0.095,0.33,0.283L143.997,10.674z"
            />
          </g>
        </g>
        <g>
          <line
            id="bottom_dash"
            fill="none"
            stroke="#000000"
            strokeWidth="2.6051"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="22.211"
            y1="16.48"
            x2="10.749"
            y2="16.48"
          />
        </g>
        <g>
          <line
            id="top_dash"
            fill="none"
            stroke="#000000"
            strokeWidth="2.6051"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="22.211"
            y1="16.48"
            x2="10.749"
            y2="16.48"
          />
        </g>
        <g id="circle">
          <circle
            id="circle"
            fill="none"
            stroke="#000000"
            strokeWidth="1.563"
            strokeMiterlimit="10"
            cx="16.48"
            cy="16.48"
            r="15.698"
          />
        </g>
        <g id="period">
          <circle cx="153.311" cy="17.638" r="1.399" />
        </g>
        <g mask="url(#taglineMask)">
          <g id="tagline">
            <g>
              <path
                d="M41.665,26.157c0,0.088-0.11,0.187-0.231,0.187c-0.077,0-0.165-0.044-0.176-0.176c-0.044-0.319-0.385-0.902-1.133-0.902
			c-0.836,0-1.595,0.638-1.595,1.87c0,1.122,0.693,2.046,1.75,2.046c0.627,0,1.001-0.297,1.177-0.561
			c0.055-0.088,0.11-0.121,0.176-0.121c0.132,0,0.253,0.154,0.187,0.319c-0.363,0.914-1.21,1.167-1.837,1.167
			c-1.375,0-2.299-1.232-2.299-2.597c0-1.452,0.99-2.662,2.629-2.662c0.594,0,1.101,0.198,1.21,0.275
			C41.654,25.089,41.665,26.157,41.665,26.157z"
              />
              <path
                d="M47.759,27.334c0,1.694-1.067,2.662-2.531,2.662c-1.364,0-2.453-0.891-2.453-2.574c0-1.848,1.177-2.707,2.552-2.707
			C46.659,24.715,47.759,25.53,47.759,27.334z M45.174,25.21c-0.847,0-1.408,0.682-1.408,1.925c0,1.232,0.671,2.321,1.595,2.321
			c1.111,0,1.408-0.913,1.408-1.848C46.769,26.718,46.395,25.21,45.174,25.21z"
              />
              <path
                d="M52.39,24.705c1.111,0,1.562,0.847,1.562,1.815c0,1.155-0.044,1.529-0.044,2.498c0,0.209,0.055,0.396,0.572,0.352
			c0.187-0.011,0.286,0.121,0.286,0.253c0,0.143-0.11,0.297-0.286,0.286c-0.704-0.044-1.155-0.077-1.947,0
			c-0.209,0.033-0.319-0.132-0.319-0.286c0-0.143,0.088-0.264,0.242-0.253c0.286,0.033,0.55-0.011,0.561-0.352
			c0.055-1.111,0.055-1.881,0.044-2.498c-0.022-0.979-0.55-1.155-0.957-1.155c-0.737,0-1.595,0.759-1.617,1.496
			c-0.033,0.902-0.055,1.782-0.011,2.134c0.033,0.22,0.143,0.429,0.539,0.374c0.187-0.022,0.275,0.121,0.275,0.264
			s-0.088,0.286-0.275,0.264c-0.836-0.044-1.155-0.066-2.024,0c-0.198,0.022-0.297-0.11-0.297-0.253s0.099-0.297,0.264-0.275
			c0.286,0.033,0.616,0,0.649-0.374c0.033-0.396,0.033-2.311-0.044-2.838c-0.066-0.44-0.242-0.561-0.55-0.462
			c-0.121,0.033-0.253-0.099-0.253-0.242c0-0.088,0.055-0.165,0.176-0.22c0.253-0.099,0.803-0.429,0.946-0.418
			c0.099,0,0.264,0.275,0.363,0.682c0.077,0.231,0.209,0.297,0.297,0.209C50.882,25.343,51.509,24.705,52.39,24.705z"
              />
              <path
                d="M55.645,29.424c-0.044-0.187-0.022-0.671-0.011-0.902c0.011-0.165,0.143-0.253,0.253-0.253
			c0.077,0,0.154,0.033,0.176,0.121c0.187,0.627,0.495,1.111,1.243,1.111c0.495,0,0.781-0.319,0.781-0.682
			c0-0.385-0.286-0.572-1.166-1.078c-0.935-0.539-1.353-0.924-1.353-1.639c0-0.902,0.858-1.419,1.65-1.419
			c0.771,0,1.342,0.231,1.441,0.33c0.099,0.099,0.088,0.77,0.088,1.1c0,0.11-0.143,0.187-0.286,0.187
			c-0.099,0-0.198-0.055-0.209-0.176c-0.033-0.44-0.451-0.968-1.111-0.968c-0.605,0-0.814,0.407-0.814,0.693
			c0,0.418,0.352,0.627,1.012,0.979c1.144,0.605,1.672,0.891,1.672,1.75c0,0.825-0.781,1.419-1.782,1.419
			C56.349,29.996,55.689,29.622,55.645,29.424z"
              />
              <path
                d="M61.476,25.937c-0.011,0.352-0.044,1.915-0.033,2.299c0.044,0.748,0.33,1.034,0.891,1.034c0.605,0,1.353-0.506,1.43-1.43
			c0.055-0.616,0.044-1.562,0.055-1.936c0.011-0.429-0.165-0.638-0.66-0.594c-0.198,0.011-0.297-0.099-0.297-0.231
			c0-0.132,0.099-0.264,0.297-0.264c0.528,0,0.935-0.033,1.177-0.088c0.11-0.033,0.308,0.011,0.341,0.165
			c0.033,0.165,0.033,0.473,0.011,1.056c-0.033,1.188-0.088,2.42,0.022,2.871c0.066,0.286,0.132,0.506,0.561,0.396
			c0.121-0.033,0.209,0.077,0.209,0.209c0,0.088-0.044,0.198-0.143,0.264c-0.253,0.143-0.913,0.308-0.979,0.308
			c-0.165,0-0.396-0.341-0.495-0.825c-0.033-0.121-0.11-0.11-0.242,0.044c-0.253,0.275-0.99,0.781-1.694,0.781
			c-1.034,0-1.331-0.693-1.375-1.76c-0.011-0.319,0.033-1.342,0.033-2.354c0-0.319-0.022-0.649-0.55-0.55
			c-0.198,0.033-0.297-0.077-0.297-0.209s0.099-0.286,0.275-0.297c0.352-0.022,0.836-0.044,1.012-0.088
			c0.077-0.011,0.319-0.033,0.385,0.033C61.563,24.979,61.486,25.419,61.476,25.937z"
              />
              <path
                d="M67.921,28.984c0.033,0.209,0.143,0.473,0.638,0.385c0.198,0,0.297,0.132,0.297,0.264c0,0.143-0.11,0.297-0.297,0.275
			c-0.77-0.055-1.463-0.066-2.222,0c-0.198,0.022-0.286-0.11-0.286-0.242c0-0.143,0.099-0.308,0.275-0.297
			c0.275,0.033,0.605-0.011,0.616-0.352c0.088-3.146,0.077-5.578-0.011-6.282c-0.044-0.374-0.264-0.286-0.627-0.242
			c-0.154,0.022-0.253-0.132-0.253-0.286c0-0.132,0.077-0.253,0.253-0.253c0.715-0.022,0.924-0.143,1.408-0.286
			c0.055,0.033,0.209,0.066,0.187,0.451C67.778,24.594,67.833,28.423,67.921,28.984z"
              />
              <path
                d="M71.012,24.605c-0.011,0.154,0.044,0.198,0.154,0.209c0.088,0,0.242,0.011,0.418,0.011c0.253,0,0.55-0.011,0.814-0.044
			c0.231-0.022,0.308,0.088,0.308,0.209c0,0.176-0.165,0.385-0.352,0.374c-0.429-0.033-1.001,0-1.177,0
			c-0.154,0-0.198,0.099-0.198,0.187c-0.033,0.935-0.044,1.98,0,2.717c0.044,0.77,0.418,0.957,0.704,0.957
			c0.374,0,0.671-0.132,0.902-0.451c0.044-0.066,0.11-0.099,0.165-0.099c0.099,0,0.176,0.077,0.176,0.209
			c0,0.088-0.033,0.187-0.121,0.308c-0.297,0.385-0.726,0.792-1.441,0.803c-1.067,0.011-1.298-0.869-1.298-1.859
			c0.011-1.167,0.033-1.354,0.033-2.344c0-0.319,0-0.473-0.528-0.429c-0.253,0.033-0.363-0.121-0.363-0.275
			c0-0.143,0.099-0.286,0.275-0.275c0.759,0.055,0.957-0.528,0.957-0.913c0-0.275,0.605-0.374,0.605-0.132
			C71.045,23.989,71.012,24.462,71.012,24.605z"
              />
              <path
                d="M76.127,29.369c0.198-0.022,0.297,0.11,0.297,0.253s-0.11,0.297-0.297,0.286c-0.814-0.055-1.298-0.066-2.277,0.011
			c-0.209,0.022-0.319-0.143-0.319-0.297c0-0.143,0.088-0.264,0.242-0.253c0.275,0.033,0.55,0.077,0.66-0.308
			c0.132-0.407,0.143-3.355-0.022-3.62c-0.088-0.198-0.22-0.154-0.572-0.11c-0.143,0.033-0.242-0.121-0.242-0.264
			c0-0.121,0.066-0.253,0.242-0.253c0.737-0.022,0.914-0.022,1.419-0.154c0.055,0.011,0.198,0.055,0.176,0.264
			c-0.099,0.924-0.033,3.565,0.033,4.115C75.5,29.27,75.61,29.435,76.127,29.369z M74.851,22.306c0.429,0,0.638,0.209,0.638,0.572
			c0,0.407-0.209,0.704-0.638,0.704c-0.44,0-0.671-0.275-0.671-0.627C74.18,22.537,74.466,22.306,74.851,22.306z"
              />
              <path
                d="M80.704,24.705c1.111,0,1.562,0.847,1.562,1.815c0,1.155-0.044,1.529-0.044,2.498c0,0.209,0.055,0.396,0.572,0.352
			c0.187-0.011,0.286,0.121,0.286,0.253c0,0.143-0.11,0.297-0.286,0.286c-0.704-0.044-1.155-0.077-1.947,0
			c-0.209,0.033-0.319-0.132-0.319-0.286c0-0.143,0.088-0.264,0.242-0.253c0.286,0.033,0.55-0.011,0.561-0.352
			c0.055-1.111,0.055-1.881,0.044-2.498c-0.022-0.979-0.55-1.155-0.957-1.155c-0.737,0-1.595,0.759-1.617,1.496
			c-0.033,0.902-0.055,1.782-0.011,2.134c0.033,0.22,0.143,0.429,0.539,0.374c0.187-0.022,0.275,0.121,0.275,0.264
			s-0.088,0.286-0.275,0.264c-0.836-0.044-1.155-0.066-2.024,0c-0.198,0.022-0.297-0.11-0.297-0.253s0.099-0.297,0.264-0.275
			c0.286,0.033,0.616,0,0.649-0.374c0.033-0.396,0.033-2.311-0.044-2.838c-0.066-0.44-0.242-0.561-0.55-0.462
			c-0.121,0.033-0.253-0.099-0.253-0.242c0-0.088,0.055-0.165,0.176-0.22c0.253-0.099,0.803-0.429,0.946-0.418
			c0.099,0,0.264,0.275,0.363,0.682c0.077,0.231,0.209,0.297,0.297,0.209C79.196,25.343,79.823,24.705,80.704,24.705z"
              />
              <path
                d="M84.85,27.807c0-0.11-0.187-0.198-0.385-0.407c-0.198-0.187-0.462-0.539-0.462-1.067c0-1.001,0.836-1.617,2.014-1.617
			c0.363,0,0.737,0.143,1.089,0.176c0.429,0.033,1.056-0.077,1.298-0.11c0.176-0.022,0.231,0.099,0.231,0.242
			c0,0.176-0.088,0.385-0.176,0.429c-0.176,0.088-0.803-0.055-0.924-0.044c-0.055,0-0.121,0.044-0.066,0.132
			c0.088,0.121,0.33,0.451,0.33,0.891c0,0.935-0.946,1.519-1.849,1.519c-0.583,0-1.056,0.352-1.056,0.429
			c0,0.088,0.099,0.165,0.275,0.22c0.539,0.187,1.705,0.396,2.585,0.748c0.451,0.176,0.726,0.605,0.726,1.023
			c0,0.979-1.199,1.76-2.685,1.76c-1.496,0-2.288-0.693-2.288-1.32c0-0.66,0.913-1.067,1.496-1.276
			c0.088-0.033,0.154-0.121,0.033-0.176c-0.44-0.188-1.122-0.363-1.122-0.737c0-0.242,0.847-0.627,0.924-0.77
			C84.85,27.84,84.85,27.818,84.85,27.807z M84.52,30.634c0,0.561,0.484,1.045,1.497,1.045c0.825,0,1.496-0.451,1.496-0.99
			c0-0.352-0.187-0.572-0.484-0.704c-0.286-0.132-0.836-0.275-1.243-0.275C85.114,29.71,84.52,30.15,84.52,30.634z M84.905,26.311
			c0,0.583,0.363,1.265,1.111,1.265c0.616,0,0.913-0.517,0.913-1.034c0-0.671-0.44-1.397-1.1-1.397
			C85.312,25.145,84.905,25.574,84.905,26.311z"
              />
              <path
                d="M90.075,32.108c-0.143,0-0.253-0.154-0.253-0.286c0-0.099,0.077-0.165,0.176-0.231c0.418-0.275,0.693-0.781,0.693-1.21
			c0-0.264-0.033-0.506-0.231-0.506c-0.099,0-0.209,0.121-0.385,0.121c-0.396,0-0.55-0.407-0.55-0.66c0-0.44,0.363-0.583,0.66-0.583
			c0.638,0,1.056,0.594,1.056,1.584c0,0.66-0.418,1.342-1.023,1.727C90.141,32.108,90.097,32.108,90.075,32.108z"
              />
              <path
                d="M99.447,26.157c0,0.088-0.11,0.187-0.231,0.187c-0.077,0-0.165-0.044-0.176-0.176c-0.044-0.319-0.385-0.902-1.133-0.902
			c-0.836,0-1.595,0.638-1.595,1.87c0,1.122,0.693,2.046,1.75,2.046c0.627,0,1.001-0.297,1.177-0.561
			c0.055-0.088,0.11-0.121,0.176-0.121c0.132,0,0.253,0.154,0.187,0.319c-0.363,0.914-1.21,1.167-1.837,1.167
			c-1.375,0-2.299-1.232-2.299-2.597c0-1.452,0.99-2.662,2.629-2.662c0.594,0,1.101,0.198,1.21,0.275
			C99.436,25.089,99.447,26.157,99.447,26.157z"
              />
              <path
                d="M103.066,29.369c0.209-0.033,0.308,0.099,0.308,0.253c0,0.154-0.099,0.319-0.286,0.297
			c-0.792-0.088-1.441-0.099-2.343-0.011c-0.231,0.022-0.341-0.132-0.341-0.286c0-0.132,0.088-0.264,0.253-0.253
			c0.286,0.033,0.66,0.088,0.715-0.308c0.066-0.418,0.066-2.387-0.022-2.916c-0.066-0.451-0.176-0.583-0.528-0.462
			c-0.121,0.044-0.253-0.088-0.253-0.231c0-0.088,0.044-0.176,0.176-0.231c0.253-0.099,0.726-0.407,0.869-0.407
			c0.066,0,0.297,0.253,0.429,0.638c0.044,0.121,0.154,0.319,0.308,0.099c0.297-0.429,0.902-0.825,1.408-0.825
			c0.462,0,0.748,0.231,0.748,0.561c0,0.418-0.407,0.616-0.792,0.495c-0.847-0.286-1.386,0.099-1.43,0.77
			c-0.077,1.144,0,2.09,0.044,2.498C102.351,29.27,102.571,29.468,103.066,29.369z"
              />
              <path
                d="M106.366,27.048c-0.231,0.022-0.352,0.11-0.352,0.352c0,1.133,0.77,1.892,1.727,1.892c0.462,0,0.891-0.22,1.155-0.528
			c0.044-0.044,0.088-0.066,0.132-0.066c0.143,0,0.264,0.209,0.143,0.385c-0.385,0.627-1.122,0.902-1.782,0.902
			c-1.54,0-2.244-1.133-2.244-2.41c0-1.463,0.924-2.849,2.453-2.849c1.199,0,1.76,0.924,1.76,1.705c0,0.253-0.132,0.484-0.407,0.506
			C108.423,26.982,106.938,26.982,106.366,27.048z M108.137,26.465c0.495-0.099,0.088-1.21-0.715-1.21
			c-0.682,0-1.111,0.605-1.21,0.968c-0.055,0.198-0.011,0.297,0.198,0.319C106.773,26.575,107.719,26.542,108.137,26.465z"
              />
              <path
                d="M110.446,26.189c0-0.781,1.32-1.463,2.245-1.463c0.935,0,1.32,0.539,1.32,1.782c0,0.55-0.055,1.111-0.055,1.727
			c0,0.528,0,1.133,0.594,0.979c0.132-0.055,0.231,0.11,0.231,0.253c0,0.088-0.033,0.176-0.121,0.209
			c-0.264,0.099-0.958,0.319-1.012,0.319c-0.099,0-0.275-0.176-0.407-0.44c-0.044-0.11-0.099-0.11-0.209-0.011
			c-0.176,0.165-0.627,0.451-1.133,0.451c-1.155,0-1.562-0.726-1.562-1.342c0-1.21,2.343-1.342,2.707-1.596
			c0.121-0.088,0.154-0.198,0.154-0.352c0-1.056-0.33-1.342-0.858-1.342c-0.352,0-0.726,0.077-0.936,0.253
			c-0.143,0.11,0,0.506,0,0.649c0,0.253-0.154,0.462-0.484,0.462C110.667,26.729,110.446,26.443,110.446,26.189z M112.911,27.609
			c-0.462,0.055-1.661,0.374-1.661,1.023c0,0.308,0.209,0.759,0.858,0.759c0.693,0,1.045-0.704,1.045-1.397
			c0-0.165-0.022-0.319-0.088-0.374C113.043,27.598,112.988,27.598,112.911,27.609z"
              />
              <path
                d="M117.024,24.605c-0.011,0.154,0.044,0.198,0.154,0.209c0.088,0,0.242,0.011,0.418,0.011c0.253,0,0.55-0.011,0.814-0.044
			c0.231-0.022,0.308,0.088,0.308,0.209c0,0.176-0.165,0.385-0.352,0.374c-0.429-0.033-1.001,0-1.177,0
			c-0.154,0-0.198,0.099-0.198,0.187c-0.033,0.935-0.044,1.98,0,2.717c0.044,0.77,0.418,0.957,0.704,0.957
			c0.374,0,0.671-0.132,0.902-0.451c0.044-0.066,0.11-0.099,0.165-0.099c0.099,0,0.176,0.077,0.176,0.209
			c0,0.088-0.033,0.187-0.121,0.308c-0.297,0.385-0.726,0.792-1.441,0.803c-1.067,0.011-1.298-0.869-1.298-1.859
			c0.011-1.167,0.033-1.354,0.033-2.344c0-0.319,0-0.473-0.528-0.429c-0.253,0.033-0.363-0.121-0.363-0.275
			c0-0.143,0.099-0.286,0.275-0.275c0.759,0.055,0.957-0.528,0.957-0.913c0-0.275,0.605-0.374,0.605-0.132
			C117.057,23.989,117.024,24.462,117.024,24.605z"
              />
              <path
                d="M122.139,29.369c0.198-0.022,0.298,0.11,0.298,0.253s-0.11,0.297-0.298,0.286c-0.813-0.055-1.298-0.066-2.277,0.011
			c-0.209,0.022-0.319-0.143-0.319-0.297c0-0.143,0.088-0.264,0.242-0.253c0.275,0.033,0.55,0.077,0.66-0.308
			c0.132-0.407,0.143-3.355-0.022-3.62c-0.088-0.198-0.22-0.154-0.572-0.11c-0.143,0.033-0.242-0.121-0.242-0.264
			c0-0.121,0.066-0.253,0.242-0.253c0.737-0.022,0.914-0.022,1.419-0.154c0.056,0.011,0.198,0.055,0.177,0.264
			c-0.1,0.924-0.033,3.565,0.033,4.115C121.512,29.27,121.623,29.435,122.139,29.369z M120.863,22.306
			c0.429,0,0.638,0.209,0.638,0.572c0,0.407-0.209,0.704-0.638,0.704c-0.44,0-0.671-0.275-0.671-0.627
			C120.192,22.537,120.479,22.306,120.863,22.306z"
              />
              <path
                d="M126.715,24.705c1.111,0,1.562,0.847,1.562,1.815c0,1.155-0.044,1.529-0.044,2.498c0,0.209,0.055,0.396,0.572,0.352
			c0.187-0.011,0.286,0.121,0.286,0.253c0,0.143-0.11,0.297-0.286,0.286c-0.704-0.044-1.155-0.077-1.947,0
			c-0.209,0.033-0.319-0.132-0.319-0.286c0-0.143,0.088-0.264,0.242-0.253c0.286,0.033,0.55-0.011,0.561-0.352
			c0.056-1.111,0.056-1.881,0.045-2.498c-0.022-0.979-0.551-1.155-0.958-1.155c-0.736,0-1.595,0.759-1.617,1.496
			c-0.032,0.902-0.055,1.782-0.011,2.134c0.033,0.22,0.144,0.429,0.539,0.374c0.188-0.022,0.275,0.121,0.275,0.264
			s-0.088,0.286-0.275,0.264c-0.836-0.044-1.155-0.066-2.024,0c-0.198,0.022-0.297-0.11-0.297-0.253s0.099-0.297,0.264-0.275
			c0.286,0.033,0.616,0,0.649-0.374c0.033-0.396,0.033-2.311-0.044-2.838c-0.066-0.44-0.242-0.561-0.55-0.462
			c-0.121,0.033-0.253-0.099-0.253-0.242c0-0.088,0.055-0.165,0.176-0.22c0.253-0.099,0.803-0.429,0.946-0.418
			c0.099,0,0.264,0.275,0.363,0.682c0.076,0.231,0.209,0.297,0.297,0.209C125.208,25.343,125.835,24.705,126.715,24.705z"
              />
              <path
                d="M130.862,27.807c0-0.11-0.187-0.198-0.385-0.407c-0.198-0.187-0.462-0.539-0.462-1.067c0-1.001,0.836-1.617,2.013-1.617
			c0.363,0,0.737,0.143,1.09,0.176c0.429,0.033,1.056-0.077,1.298-0.11c0.176-0.022,0.231,0.099,0.231,0.242
			c0,0.176-0.089,0.385-0.177,0.429c-0.176,0.088-0.803-0.055-0.924-0.044c-0.055,0-0.121,0.044-0.066,0.132
			c0.089,0.121,0.33,0.451,0.33,0.891c0,0.935-0.945,1.519-1.848,1.519c-0.583,0-1.057,0.352-1.057,0.429
			c0,0.088,0.1,0.165,0.275,0.22c0.539,0.187,1.705,0.396,2.585,0.748c0.451,0.176,0.727,0.605,0.727,1.023
			c0,0.979-1.199,1.76-2.685,1.76c-1.496,0-2.288-0.693-2.288-1.32c0-0.66,0.913-1.067,1.496-1.276
			c0.088-0.033,0.154-0.121,0.033-0.176c-0.44-0.188-1.122-0.363-1.122-0.737c0-0.242,0.847-0.627,0.924-0.77
			C130.862,27.84,130.862,27.818,130.862,27.807z M130.532,30.634c0,0.561,0.484,1.045,1.496,1.045c0.825,0,1.496-0.451,1.496-0.99
			c0-0.352-0.187-0.572-0.483-0.704c-0.286-0.132-0.836-0.275-1.243-0.275C131.126,29.71,130.532,30.15,130.532,30.634z
			 M130.917,26.311c0,0.583,0.362,1.265,1.11,1.265c0.616,0,0.913-0.517,0.913-1.034c0-0.671-0.439-1.397-1.1-1.397
			C131.324,25.145,130.917,25.574,130.917,26.311z"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

Logo.propTypes = propTypes;
export default Logo;
